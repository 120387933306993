<template>
  <div class="main">
    <HeaderTitle></HeaderTitle>
    <div class="center">
      <div class="centerTitle c">
        <img src="@/assets/jobFair/title.png" alt="" />
        <span class="titleText">"寻找在人群中闪闪发光的你"</span>
        <span class="titleText2">招兵买马，我们需要你</span>
      </div>
      <div class="lately c">
        <el-carousel trigger="click" height="600px" :interval="0" arrow="never">
          <el-carousel-item v-for="(item, index) in applyList" :key="index">
            <div class="jobBox">
              <div
                class="jobItem"
                v-for="(aitem, aindex) in applyList[index]"
                :key="aindex"
              >
                <div class="item">
                  <img :src="aitem.litimg" alt="" />
                  <span class="name">{{ aitem.title }}</span>
                  <span class="info">{{ aitem.intro }}</span>
                  <div class="time">
                    <span>
                      <i class="iconfont icon-shijian"></i>
                      {{
                        aitem.beginTime.substring(
                          0,
                          aitem.beginTime.indexOf(' ')
                        )
                      }}
                    </span>
                    <!-- <span @click.stop="sginUpJobFair(aitem.id)">立即报名</span> -->
                    <span @click.stop="toJobFairDetails(aitem.id)">立即报名</span>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="previousPeriod bgc5 c2">
        <ul>
          <li v-for="(item, index) in jobFairList" :key="index">
            <!-- <img src="@/assets/jobFair/矩形 7.png" alt="" /> -->
            <div class="imgBox">
              <img :src="$imgUrl(item.litimg)" alt="" />
            </div>
            <!-- <img :src="$global.baseUrl + imgUrl + item.litimg" alt="" /> -->
            <div class="liRight">
              <span class="name" @click="toJobFairDetails(item.id,1)">{{ item.title }}</span>
              <span class="info font6">{{ item.intro }}</span>
              <div class="time">
                <span class="font6">
                  <i class="iconfont icon-shijian"></i>
                  {{ item.beginTime.substring(0, item.beginTime.indexOf(' ')) }}
                </span>
                <span class="font6">
                  <i class="iconfont icon-31dingwei"></i>
                  {{ item.address }}
                </span>
              </div>
              <span class="held">已举办</span>
            </div>
          </li>
        </ul>
        <div class="moreBtnBox">
          <div class="moreBtn" @click="getMoreJobFair">点击查看更多</div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import HeaderTitle from '@/components/hometitle/hometitle.vue'
import Footer from '@/components/footer/footers.vue'
import { jobFairApplyList, jobFairPage,userApply } from '@/api/jobFair/jobFair'
import moment from 'moment'
export default {
  name: '',
  data() {
    return {
      applyList: [], //最近招聘会列表
      jobFairList: [], //往期招聘会列表
      parmas: {
        pageNo: 1,
        pageSize: 5,
        btime: '',
        ntime: ''
      },
    }
  },
  watch: {},
  props: {},
  components: {
    HeaderTitle,
    Footer,
  },
  computed: {},
  created() {
    this.getJobFairApplyList()
    this.getJobFairPage()
  },
  mounted() {},
  methods: {
    arrChange(num, arr) {
      const newArr = []
      while (arr.length > 0) {
        newArr.push(arr.splice(0, num))
      }
      return newArr
    },
    // 最近招聘会列表
    getJobFairApplyList() {
      let params = {
					auditStatus: 2
				}
      jobFairApplyList(params).then(res => {
        console.log(res.data.filter(i => i.auditStatus == 2),'res.data.filter(i => i.auditStatus == 2)')
        let applyList = res.data.filter(i => i.auditStatus == 2)
        this.applyList = this.arrChange(3, applyList)
      })
    },
    // 往期招聘会列表
    getJobFairPage() {
      this.parmas.etime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      jobFairPage(this.parmas).then(res => {
        this.jobFairList = res.data.rows
      })
    },
    // 查看更多往期招聘会
    getMoreJobFair() {
      this.parmas.pageNo++
      this.parmas.ntime = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      jobFairPage(this.parmas).then(res => {
        console.log(res)
        this.jobFairList = [...this.jobFairList, ...res.data.rows]
        if (res.data.totalPage < this.parmas.pageNo) {
          this.$message({
            message: '暂无更多往期招聘会信息',
            type: 'warning',
          })
        }
      })
    },
    // 进入招聘会详情
    toJobFairDetails(id,held) {
      // this.$router.push({query:{id:id},path:'/jobFairDetails'})
      this.$router.push({
        path: '/jobFairDetails',
        query: {
          id:id,
          held:held
        }
      })
    },
    // sginUpJobFair(id) {
    //   let userId = localStorage.getItem('userId')
    //   if(!userId) {
    //     this.$message.warning('暂未登录，请先前往登录')
    //     return
    //   }
    //   let obj = {
    //     jobFairId: id,
    //     jobUserId: localStorage.getItem('userId'),
    //   }
    //   userApply(obj).then(res => {
    //     console.log(res, '招聘会报名')
    //     if (res.success && res.code == 200) {
    //       this.$message({
    //         message: '报名成功!',
    //         type: 'success',
    //       })
    //     } else {
    //       this.$message.error(res.message)
    //     }
    //   })
    // }
  },
  filters: {},
}
</script>

<style scoped lang="scss">
.c {
  width: 1200px;
  margin: 0 auto;
}
.c2 {
  width: 1160px;
  margin: 0 auto;
}
.font6 {
  color: #666;
}
.center {
  background: url('~@/assets/jobFair/bgc1.png');
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding-bottom: 100px;
}
.center::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url('~@/assets/jobFair/bgc2.png');
}
.centerTitle {
  display: flex;
  flex-direction: column;
  padding: 200px 0 160px 0;
  img {
    width: 510px;
    height: 96px;
  }
  .titleText {
    color: #fff;
    font-size: 24px;
    text-shadow: 2px 2px #2f948a;
    margin: 30px 0;
  }
  .titleText2 {
    color: #fff;
    font-size: 24px;
    // padding: 8px 20px;
    background: linear-gradient(to right, #edaf7f, #f28e42);
    width: 240px;
    height: 48px;
    line-height: 48px;
    padding: 0 20px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}

.lately {
  height: 626px;
  background: url('~@/assets/jobFair/bgc3.png');
  background-size: 100% 100%;
  position: relative;
  margin-bottom: 84px;
  z-index: 1;
  .jobBox {
    display: flex;
    margin-top: 56px;
    .jobItem {
      width: 33.33%;
      height: 520px;
      display: flex;
      justify-content: center;
      align-items: center;
      .item {
        width: 324px;
        height: 450px;
        border-radius: 10px;
        background-color: #ffe1c9;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        // padding: 26px 30px 14px 30px;
        img {
          width: 220px;
          height: 220px;
          border-radius: 6px;
          object-fit: cover;
          margin-top: 20px;
          cursor: pointer;
        }
        .name {
          font-size: 18px;
          padding: 0 20px;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          cursor: pointer;
        }
        .info {
          font-size: 14px;
          -webkit-line-clamp: 2;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          padding: 0 40px;
          margin-bottom: 40px;
          cursor: pointer;
        }
        .time {
          display: flex;
          justify-content: center;
          align-items: center;
          span:nth-child(1) {
            margin-right: 40px;
            font-size: 14px;
            color: #666;
          }
          span:nth-child(2) {
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            color: #fff;
            font-size: 14px;
            background-color: #f28e41;
            border-radius: 4px;
            cursor: pointer;
          }
        }
      }
      .item::after {
        position: absolute;
        content: '';
        width: 194px;
        height: 32px;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #edaf80;
        border-top-left-radius: 50px;
        border-top-right-radius: 50px;
        border-bottom-left-radius: 50px;
        border-bottom-right-radius: 50px;
      }
    }
    .jobItem:nth-last-child(1) {
      margin-right: 0;
    }
  }
  ::v-deep .is-active .el-carousel__button {
    background-color: #f28e41;
  }
  ::v-deep .el-carousel__button {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #ccc;
  }
}
.lately::after {
  width: 314px;
  height: 90px;
  position: absolute;
  background: url('~@/assets/jobFair/bgcTitle.png');
  background-size: 100% 100%;
  content: '';
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}

.previousPeriod {
  position: relative;
  background: url('~@/assets/jobFair/bgc4.png');
  background-size: 100% 100%;
  z-index: 1;
  padding: 40px 20px;
  ul,
  li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  ul {
    padding: 0 80px;
    height: 1230px;
    overflow-y: auto;
  }
  ul::-webkit-scrollbar {
    width: 10px;
  }
  ul::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    background: #ffd2b0;
  }
  div:-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 0;
    background: #ffd2b0;
  }

  li {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #ccc;
    padding-top: 30px;
    z-index: 1;
    // img {
    //   width: 180px;
    //   height: 180px;
    //   margin-right: 80px;
    // }
    .imgBox {
      width: 180px;
      height: 180px;
      margin-right: 80px;
      img {
        width: 180px;
        height: 180px;
        object-fit: cover;
      }
    }
    .liRight {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      position: relative;
      .name {
        font-size: 24px;
        margin-bottom: 10px;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        cursor: pointer;
      }
      .name:hover {
        text-decoration: underline;
      }
      .info {
        font-size: 18px;
        margin-bottom: 40px;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      .time {
        font-size: 14px;
        display: flex;
        span:nth-child(1) {
          // margin-right: 70px;
          width: 170px;
        }
        i {
          margin-right: 10px;
        }
        span:nth-child(2) {
          flex: 1;
          -webkit-line-clamp: 1;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
      .held {
        font-size: 56px;
        font-weight: bold;
        color: #f28e41;
        position: absolute;
        right: 0;
        opacity: 0.3;
        transform: rotate(-30deg);
      }
    }
  }
  .moreBtnBox {
    display: flex;
    justify-content: center;
    .moreBtn {
      // margin-bottom: 50px;
      margin: 30px 0 20px;
      width: 168px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      background-color: #a66839;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      z-index: 1;
    }
  }
}
.previousPeriod::after {
  position: absolute;
  content: '';
  width: 314px;
  height: 90px;
  background: url('~@/assets/jobFair/bgcTitle2.png');
  background-size: 100% 100%;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.previousPeriod::before {
  position: absolute;
  content: '';
  width: 680px;
  height: 570px;
  background: url('~@/assets/jobFair/bgc5.png');
  background-size: 100% 100%;
  bottom: 0;
  left: -140px;
}
</style>
